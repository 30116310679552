import React from 'react';

import Layout from '../components/Layout';
import Header from '../components/Header';
import Email from '../components/Email';

const IndexPage = () => (
  <Layout>
    <section id="main">
      <Header />
      <Email />
    </section>
  </Layout>
);

export default IndexPage;
