import React from 'react';
import config from '../../config';
import Obfuscate from 'react-obfuscate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

export default function Footer() {
  return (
    <footer>
      <ul className="icons">
        <li>
            <Obfuscate
                email={config.email}
            >
                <FontAwesomeIcon
                    icon={faEnvelope}
                    size='3x'
                />
            </Obfuscate>
        </li>
      </ul>
    </footer>
  );
}
